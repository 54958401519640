import type { Web3Confirmation } from "@/composables/blockchain/Web3ProviderAdapter";
import type { SendParams } from "@/composables/blockchain/Web3Provider";
import type { BlockchainConfig } from "@/composables/blockchain/ContractAddresses";
import Web3 from "web3";
import Web3NetworkChecker from "./blockchain/Web3MetamaskProvider";
import type { ProviderAccount } from "~/stores/mainStore";
import { Web3WalletProviderInstance } from "./Web3WalletProvider";
import Web3ProviderAdapter from "@/composables/blockchain/Web3ProviderAdapter";

export class Web3StakingService {
    constructor(
        private readonly providerAccount: ProviderAccount,
        private readonly configuration: BlockchainConfig
    ) {
    }

    private async sendContractMethod(sendParams: SendParams, onConfirmation?: Web3Confirmation) {
        const ethereumProvider = Web3WalletProviderInstance
            .getExistingProvider(this.providerAccount.providerKind);
        const from = this.providerAccount.address;
        await Web3NetworkChecker.ensureCorrectNetwork(
            ethereumProvider, this.configuration
        );
        return new Web3ProviderAdapter(new Web3(ethereumProvider))
            .sendContractMethod({ ...sendParams, from }, onConfirmation);
    }

    public async approve(token: string, quantity: number, onConfirmation?: Web3Confirmation): Promise<string> {
        const contractAddr = this.configuration.contracts.pearlStore;

        const hash = await this.sendContractMethod(
            {
                contract: "erc20",
                address: token, // USDT
                methodName: "approve",
                methodArguments: [contractAddr, Web3.utils.toWei(quantity.toString(), "ether")],
            },
            onConfirmation
        );

        return hash;
    }

    /**
     * buyPearlPack
     * @param value - human readable value like 0.1 USDT
     * @param onConfirmation - confirmation callback, fired on each head block
     */
    public async buyPearlPack(value: string, onConfirmation?: Web3Confirmation): Promise<string> {
        const amount = Web3.utils.toWei(value, "ether");

        return this.sendContractMethod(
            {
                contract: "pearlStore",
                address: this.configuration.contracts.pearlStore,
                methodName: "buyPearlPack",
                methodArguments: [amount],
            },
            onConfirmation
        );
    }

    /**
     * Unstake pearl on a contract
     * @param onConfirmation - confirmation callback, fired on each head block
     */
    public async exit(onConfirmation?: Web3Confirmation): Promise<string> {
        return this.sendContractMethod(
            {
                contract: "pearlStore",
                address: this.configuration.contracts.pearlStore,
                methodName: "exit",
            },
            onConfirmation
        );
    }

    /**
     * Unstake prearl token on a contract
     * @param value - human readable value like 0.1 prearl
     * @param onConfirmation - confirmation callback, fired on each head block
     */
    public async unstake(value: string, onConfirmation?: Web3Confirmation): Promise<string> {
        const amount = Web3.utils.toWei(value, "ether");

        return this.sendContractMethod(
            {
                contract: "pearlStore",
                address: this.configuration.contracts.pearlStore,
                methodName: "withdraw",
                methodArguments: [amount],
            },
            onConfirmation
        );
    }

    public async claimReward(onConfirmation?: Web3Confirmation): Promise<string> {
        return this.sendContractMethod(
            {
                contract: "pearlYield",
                address: this.configuration.contracts.pearlYield,
                methodName: "claim",
            },
            onConfirmation
        );
    }

    public async unlock(index: number, onConfirmation?: Web3Confirmation): Promise<string> {
        return this.sendContractMethod(
            {
                contract: "pearlYield",
                address: this.configuration.contracts.pearlYield,
                methodName: "unlock",
                methodArguments: [index],
            },
            onConfirmation
        );
    }

    public async getReward(onConfirmation?: Web3Confirmation): Promise<string> {
        return this.sendContractMethod(
            {
                contract: "pearlStore",
                address: this.configuration.contracts.pearlStore,
                methodName: "getReward",
            },
            onConfirmation
        );
    }
}
