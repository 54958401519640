

import type { TransactionReceipt } from "web3-core";
import AbiAccess from "~/composables/blockchain/AbiAccess";
// import { ShowLoader } from "@/common/decorators/ShowLoader";
import type Web3 from "web3";
import type { SendOptions } from "web3-eth-contract";
import type { CallAbiContract } from "./Web3Provider";

export type TransactionHash = string;

export type Web3Confirmation = (confNumber: number, receipt: TransactionReceipt) => void;

/**
 * takes a web3 instance and some particular wallet storage provider and
 * provides few convenient functions to interact with blockchain
 */
export default class Web3ProviderAdapter {
    constructor(private readonly web3: Web3) {
    }

    /**
     * invoke method that alters blockchain (and therefore costs gas)
     * @param sendParams - invocation transaction parameters like callData, value, etc.
     * @param onConfirmation - is callback fired on transaction confirmation
     * (by default web3 has 24 block for tx confirmation)
     */
    public async sendContractMethod(
        sendParams: CallAbiContract & SendOptions,
        onConfirmation?: Web3Confirmation
    ): Promise<TransactionHash> {
        const method = new AbiAccess(this.web3)
            .getMethodObj(sendParams);
        const gasEstimation = await method.estimateGas(sendParams);
        const sending = method.send({
            ...sendParams,
            // use web3 gas estimation but multiply to be safe
            gas: Math.round(gasEstimation * 1.2),
        });
        return new Promise<string>((resolve, reject) => {
            if (onConfirmation) sending.on("confirmation", onConfirmation);
            sending.on("transactionHash", resolve);
            sending.on("error", reject);
        });
    }
}
